import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import 'bootstrap/js/src/carousel';
import { CTAClickCategory, CTAClickLevel, PromoType } from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT } from 'datalayer-service/src/event-objects';
import { getProductEventDataWithMattressHierarchy } from 'shared-ui/src/utils/dataLayer';
import fetch from 'shared-ui/src/utils/fetchService';
import PromoBox from 'shared-ui/src/components/atoms/PromoBox';
import {
  formatProductToDeal,
  getIsOnlyOnlinePromotionFromDeal,
  openDealOutboundUrl,
  openProductDiscountOutboundUrl,
} from 'shared-ui/src/utils/promos';
import useProductDiscountModal from 'shared-ui/src/hooks/useProductDiscountModal';
import { PayoutType } from 'shared-ui/src/types/promotion';

const FEATURED_ONLINE_DISCOUNTS_CAROUSEL = 'featuredOnlineDiscountsCarousel';
const ACTIVE = 'active';
const PREV = 'prev';
const NEXT = 'next';

const ITEMS_PER_ARRAY = 2;

function FeaturedOnlineDiscounts({ pageRegion, onGetDeal }) {
  useProductDiscountModal(
    window.userData,
    null,
    (s, promo, data) => {
      onGetDeal(formatProductToDeal(data));
    },
    { pageRegion, promoType: PromoType.ONLINE },
  );
  const [data, setData] = useState({ deals: [], isFetching: true });
  const [setupCarousel, setSetupCarousel] = useState(false);
  const lastItem = data.deals.length - 1;

  const getCarouselPrevAndNextClassesIndexes = (activeIdx) => {
    return {
      activeItemIdx: activeIdx,
      prevItemIdx: activeIdx === 0 ? lastItem : activeIdx - 1,
      nextItemIdx: activeIdx === lastItem ? 0 : activeIdx + 1,
    };
  };

  const addNextAndPrevClasses = (items, activeIdx) => {
    const { prevItemIdx, nextItemIdx } = getCarouselPrevAndNextClassesIndexes(activeIdx);
    const prevItem = items[prevItemIdx];
    const nextItem = items[nextItemIdx];

    prevItem.classList.add(PREV);
    nextItem.classList.add(NEXT);
  };

  const setupFeaturedOnlineDiscountsCarousel = () => {
    const carouselElement = $(`#${FEATURED_ONLINE_DISCOUNTS_CAROUSEL}`);
    const carouselItems = carouselElement[0].children;

    carouselElement.carousel('cycle');

    addNextAndPrevClasses(carouselItems, 0);

    carouselElement.on('slide.bs.carousel', (e) => {
      const { prevItemIdx, nextItemIdx } = getCarouselPrevAndNextClassesIndexes(e.from);
      const prevItem = carouselItems[prevItemIdx];
      const nextItem = carouselItems[nextItemIdx];

      prevItem.classList.remove(PREV);
      nextItem.classList.remove(NEXT);

      addNextAndPrevClasses(carouselItems, e.to);
    });

    setSetupCarousel(false);
  };

  const groupDeals = (deals) => {
    let dealsIdx = 0;
    const groupedDeals = Array(deals.length / 2)
      .fill(null)
      .map(() => Array(ITEMS_PER_ARRAY));

    groupedDeals.forEach((d, idx) => {
      for (let i = 0; i < ITEMS_PER_ARRAY; i++) {
        groupedDeals[idx][i] = deals[dealsIdx];
        dealsIdx += 1;
      }
    });

    return groupedDeals;
  };

  useEffect(() => {
    fetch
      .get('/promos/featured-online-discounts/')
      .then((response) => {
        if (response && response.deals) {
          setData({
            deals: groupDeals(response.deals),
            isFetching: false,
          });
        } else
          setData({
            deals: [],
            isFetching: false,
          });
      })
      .catch((err) => {
        console.error('Error fetching featured-online-discounts:', err);
        setData({
          deals: [],
          isFetching: false,
        });
      });
  }, []);

  useEffect(() => {
    if (setupCarousel) setupFeaturedOnlineDiscountsCarousel();
  }, [setupCarousel]);

  const getPayoutSubtitle = (item) => {
    if (item.promotions[1]?.payout_type === PayoutType.FIXED) {
      return `+ $${item.promotions[1].fixed_payout_amount} GoodBed Cash Back`;
    }
    return `+ ${item.promotions[1].percentage_payout}% GoodBed Cash Back`;
  };

  return (
    <div
      className={`featured-online-discounts ${data.isFetching ? 'row align-items-center justify-content-center' : ''}`}
    >
      {data.isFetching ? (
        <div className="spinner-border">
          <span className="sr-only">Loading...</span>
        </div>
      ) : (
        <div id={FEATURED_ONLINE_DISCOUNTS_CAROUSEL} className="carousel slide">
          {data.deals.map((deal, idx) => {
            if (idx === lastItem && !setupCarousel) setSetupCarousel(true);

            return (
              <div
                // eslint-disable-next-line
                key={`featured-online-discounts-carousel-item-${idx}`}
                className={`carousel-item ${idx === 0 ? ACTIVE : ''}`}
              >
                {deal.map((item) => {
                  if (!item) return null;

                  return (
                    <div key={item.brand.slug} className="promotion">
                      <PromoBox
                        title={item.promotions[0].title_short}
                        subTitle={item.promotions[1] ? getPayoutSubtitle(item) : item.promotions[0].subtitle}
                        imageSrc={item.retailer.logo}
                        variant="dashed"
                        ctaData={{
                          category:
                            item.promotions.length === 1
                              ? CTAClickCategory.ONLINE_PROMOTION
                              : CTAClickCategory.CASHBACK_PROMOTION,
                          level: CTAClickLevel.PRIMARY,
                          url: '/',
                          pageRegion,
                          product: item.brand
                            ? getProductEventDataWithMattressHierarchy(item.brand)
                            : EMPTY_EVENT_PRODUCT,
                        }}
                        onClick={() => {
                          const d = {
                            brand: item.brand,
                            deals: [item],
                          };

                          if (getIsOnlyOnlinePromotionFromDeal(d)) {
                            const { classname, slug } = item.brand;
                            const { id, outbound_url: outboundURL } = item.promotions[0];

                            openProductDiscountOutboundUrl(id, classname, slug, outboundURL);
                          } else openDealOutboundUrl(d);
                        }}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })}
          <button
            className="carousel-control-prev"
            type="button"
            data-target={`#${FEATURED_ONLINE_DISCOUNTS_CAROUSEL}`}
            data-slide="prev"
          >
            <i className="carousel-control-prev-icon fal fa-chevron-left" />
            <span className="sr-only">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-target={`#${FEATURED_ONLINE_DISCOUNTS_CAROUSEL}`}
            data-slide="next"
          >
            <i className="carousel-control-next-icon fal fa-chevron-right" />
            <span className="sr-only">Next</span>
          </button>
        </div>
      )}
    </div>
  );
}

FeaturedOnlineDiscounts.propTypes = {
  pageRegion: PropTypes.string.isRequired,
  onGetDeal: PropTypes.func.isRequired,
};

export default FeaturedOnlineDiscounts;

export function renderFeaturedOnlineDiscounts(onGetDeal) {
  const element = document.getElementById('featured-online-discounts');

  if (element) {
    const pageRegion = element.getAttribute('data-page-region');

    const root = createRoot(element);
    root.render(<FeaturedOnlineDiscounts pageRegion={pageRegion} onGetDeal={onGetDeal} />);
  }
}
