import React, { FC, useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import { Carousel } from 'react-bootstrap';

import cls from 'classnames';
import Modal from '../../atoms/Modal';
import SmoothChevronIcon from '../../atoms/Icons/SmoothChevron';
import useBreakPoints from '../../../hooks/useBreakPoints';
import VideoPlayer from '../VideoPlayer';

import styles from './MediaModal.module.scss';

export const MEDIA_MODAL_ID = 'media-modal';

export interface MediaElement {
  alt: string;
  url?: string; // Video or Image URL
  isVideo?: boolean;
  youtubeId?: string;
  title?: string;
}

interface MediaModalProps {
  show: boolean;
  isLoading: boolean;
  media: MediaElement[];
  onHide: () => void;
  unmount?: () => void; // this for Django React component
  title?: string;
  className?: string;
  showImageTitle?: boolean;
  rightContent?: (currentMediaIndex: number) => JSX.Element;
  testId?: string;
  initialIndex?: number;
}

const MediaModal: FC<MediaModalProps> = ({
  show,
  onHide,
  title,
  unmount,
  isLoading,
  media,
  className,
  rightContent,
  showImageTitle,
  testId = MEDIA_MODAL_ID,
  initialIndex = 0,
}) => {
  const { isMediumScreenSize } = useBreakPoints();
  const [playingVideos, setPlayingVideos] = useState<boolean[]>([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState(initialIndex);

  const ready = !isLoading;

  const anyVideoPlaying = playingVideos.some((isPlaying) => !!isPlaying);

  useEffect(() => {
    setPlayingVideos(new Array(media.length || 0).fill(false));
  }, [media]);

  const onVideoStateChange = (videoIndex: number, state: 'pause' | 'play'): void => {
    const playingVideosCopy = [...playingVideos];
    playingVideosCopy[videoIndex] = state === 'play';

    setPlayingVideos(playingVideosCopy);
  };

  const hasMoreThanOneMedia = media.length > 1;

  const getModalBody = (): JSX.Element => {
    const currentMediaTitle = media.length ? media[currentMediaIndex].title : '';

    return (
      <>
        <ReactPlaceholder
          ready={ready}
          type="rect"
          style={{ height: isMediumScreenSize ? '232px' : '400px', width: '100%', margin: '0' }}
          showLoadingAnimation
        >
          <div className={styles.videoContent}>
            <Carousel
              interval={anyVideoPlaying ? null : 5000}
              className={cls(styles.mediaCarousel)}
              prevIcon={<SmoothChevronIcon className={styles.chevronCarouselPrev} color="#4561A4" />}
              nextIcon={<SmoothChevronIcon className={styles.chevronCarouselNext} color="#4561A4" rotate={180} />}
              indicators={hasMoreThanOneMedia}
              controls={hasMoreThanOneMedia}
              activeIndex={currentMediaIndex}
              onSelect={(selectedIndex): void => {
                setCurrentMediaIndex(selectedIndex);
              }}
            >
              {media.map(({ url, alt, isVideo, youtubeId }, index) => {
                if (isVideo && (youtubeId || url)) {
                  const key = youtubeId || url;

                  return (
                    <Carousel.Item key={`${key}-video`}>
                      <div className={styles.mediaCarouselItemContainer}>
                        <VideoPlayer
                          youtubeEmbedUrl={url}
                          youtubeId={youtubeId}
                          key={`${youtubeId}-review-video`}
                          onPlay={(): void => {
                            onVideoStateChange(index, 'play');
                          }}
                          onPause={(): void => {
                            onVideoStateChange(index, 'pause');
                          }}
                        />
                      </div>
                    </Carousel.Item>
                  );
                }

                return (
                  <Carousel.Item key={`${url}-video`}>
                    <div className={styles.mediaCarouselItemContainer}>
                      <img src={url} alt={alt} loading="lazy" />
                    </div>
                  </Carousel.Item>
                );
              })}
            </Carousel>

            {isMediumScreenSize && currentMediaTitle && showImageTitle ? (
              <p className={styles.videoContentMobileTitle}>{currentMediaTitle}</p>
            ) : null}
          </div>
        </ReactPlaceholder>

        {rightContent ? rightContent(currentMediaIndex) : null}
      </>
    );
  };

  return (
    <Modal
      show={show}
      onHide={(): void => {
        if (unmount) unmount();

        onHide();
      }}
      body={getModalBody()}
      title={
        title && isMediumScreenSize ? (
          <ReactPlaceholder
            ready={ready}
            type="textRow"
            showLoadingAnimation
            style={{
              height: isMediumScreenSize ? '30px' : '0',
              width: '180px',
              margin: 'auto',
            }}
          >
            {title}
          </ReactPlaceholder>
        ) : (
          ''
        )
      }
      bodyClassName={!hasMoreThanOneMedia ? styles.hasOneMedia : ''}
      className={cls(styles.mediaModal, className)}
      backdropIndex="1050"
      testId={testId}
    />
  );
};

export default MediaModal;
